import {
  AWSConfig,
  AuthConfig,
  CognitoConfig,
  AppSyncConfig,
  AwsSdkConfig,
  OrderQueueConfig,
  ServerlessAPIConfig,
} from "./aws-config";
import Amplify from "aws-amplify";
import AWSAppSyncClient from "aws-appsync";
import aws from "aws-sdk";
import * as localForage from "localforage";
import { DEFAULT_BRAND } from "./constants";

let env;

export const getEnv = (brandId) => {
  try {
    brandId = brandId || DEFAULT_BRAND;
    if (env && env[brandId]) return env[brandId];

    const stage = process.env.REACT_APP_STAGE.trim() || "develop"; //@RS

    console.log(`Initialize env for ${brandId} ${stage} stage`);

    // For Cognito authentication
    Amplify.configure({
      ...AWSConfig,
      ...AuthConfig[stage],
    });

    // AWS SDK
    aws.config.update(AwsSdkConfig);

    // AppSync client
    const appSyncClient = new AWSAppSyncClient({
      ...AppSyncConfig[brandId][stage],
      offlineConfig: {
        keyPrefix: brandId,
        storage: localForage,
      },
    });

    // SQS client for sending to Order queue
    const orderSQS = new aws.SQS({
      params: {
        QueueUrl: OrderQueueConfig[brandId][stage].url,
      },
    });

    env = env || {};
    env[brandId] = {
      stage,
      appSyncClient,
      orderSQS,
      authConfig: AuthConfig[stage].Auth,
      cognitoConfig: CognitoConfig[stage],
      serverlessApi: ServerlessAPIConfig[brandId][stage],
    };

    return env[brandId];
  } catch (e) {
    console.log(e);
  }
};
